<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1"> 
          AVENIR
        </h2>
      </b-link>
      <!-- /Brand logo-->
      <!-- Change password-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="4"
          lg="4"
          class="px-xl-2 mx-auto"
        >
          <h2 class="brand-text text-primary ml-1 d-lg-flex align-items-center justify-content-center">
            {{ $t('login.welcome.title') }}, <b class="text-uppercase username">{{ username }}</b>
          </h2>
          <change-password :token="token" :username="username" />
          <br>
          <!-- return buttons -->
          <b-button 
            @click="toLogin"
            block
          >
            {{ $t('login.button.return') }}
          </b-button>
        </b-col>
      </b-col>
    <!-- /Change password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BRow, BCol, BLink, BButton } from 'bootstrap-vue'
import ChangePassword from './ChangePassword.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BButton,
    ChangePassword
  },
  data() {
    return {
      username: null,
      token: null
    }
  },
  mounted() {
    this.token = this.$route.query.token
    this.username = this.$route.query.user
  },
  methods: {
    toLogin() {
      this.$router.push({ name: 'auth-login' })
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
